/* eslint-disable react/prop-types */
import OptionButton from 'client/modules/Response/components/AdditionalQuestionForm/OptionButtons/OptionButton';
import OptionButtonGroup from 'client/modules/Response/components/AdditionalQuestionForm/OptionButtons/OptionButtonGroup';
import ToggleButton from 'client/modules/Response/components/AdditionalQuestionForm/ToggleButton';
import ToggleButtonGroup from 'client/modules/Response/components/AdditionalQuestionForm/ToggleButtonGroup';
import FreeResponseTypes from 'client/modules/Surveys/pages/CustomizeSurvey/FreeResponseTypes';
import {
  freeResponse,
  scale,
  selectMany,
  selectOne,
} from 'client/modules/Surveys/pages/CustomizeSurvey/QuestionTypes';
import lightenHex from 'client/util/lightenHex';
import replacePlaceholders from 'client/util/replacePlaceholders';
import * as React from 'preact';
import { useCallback, useEffect, useMemo, useRef, useState } from 'preact/hooks';
import styles from './AdditionalQuestionForm.css';

// eslint-disable-next-line no-unused-vars
const { h } = React;

const numberArrays = [[], [], [1, 5], [1, 3, 5], [1, 2, 4, 5], [1, 2, 3, 4, 5]];

const getValues = (range, style) => {
  if (style === 'faces') {
    return numberArrays[range];
  }
  return [...Array(range).keys()].map(i => i + (range < 11 ? 1 : 0));
};

const getChoiceLimits = (question, messages) => {
  const minChoices = question.choiceLimit === 'unlimited' ? 0 : +question.minChoices;
  const maxChoices = question.choiceLimit === 'range' ? +question.maxChoices : minChoices;
  let selectMessage = messages.selectManyUnlimited;
  if (minChoices > 0 && minChoices === maxChoices) {
    selectMessage =
      minChoices === 1 ? messages.selectOne : messages.selectManyExact.replace('{num}', minChoices);
  } else if (minChoices > 0) {
    selectMessage = maxChoices
      ? messages.selectManyRangeBetween.replace('{min}', minChoices).replace('{max}', maxChoices)
      : messages.selectManyRangeUp.replace('{num}', minChoices);
  } else if (maxChoices > 0) {
    selectMessage = messages.selectManyRangeDown.replace('{num}', maxChoices);
  }
  return { minChoices, maxChoices, selectMessage };
};

const getValue = (type, value) => {
  let ret = value;
  if ([selectOne, selectMany].includes(type)) {
    ret = ret
      .filter(v => v.checked)
      .map(({ id, comment }) => ({
        id,
        comment,
      }));
  }
  if (selectOne === type) {
    return ret[0];
  }
  return ret;
};

const AdditionalQuestionForm = ({
  question,
  dir,
  hasPrevious,
  hasNext,
  buttonColor,
  onSubmit = () => {},
  onPrevious = () => {},
  value = null,
  setValue,
  messages,
  dark,
  disabled,
  stopEditingMessage,
  additionalQuestions,
  values,
}) => {
  const rand = useRef([...new Array(100)].map(() => Math.random()));

  const optionsSorted = useCallback(q => {
    if (!q.optionsAttributes) {
      return [];
    }
    if (!q.randomizeOptionOrder) {
      return q.optionsAttributes;
    }
    const newOptions = q.optionsAttributes.map((o, i) => ({
      ...o,
      order: q.randomizeExceptLast && i === q.optionsAttributes.length - 1 ? 2 : rand.current[i],
    }));
    return newOptions.sort((a, b) => a.order - b.order);
  }, []);

  const formRef = useRef(null);
  const [submits, setSubmits] = useState(0);
  const [error, setError] = useState(null);
  useEffect(() => {
    setSubmits(0);
  }, [question, disabled, setSubmits]);
  useEffect(() => {
    const invalidHandler = () => {
      setSubmits(s => s + 1);
      setError(messages.requiredAnswer);
    };
    const inputHandler = () => {
      if (!formRef.current.checkValidity || formRef.current.checkValidity()) {
        setError(null);
      }
    };
    if (formRef.current) {
      const requiredInputs = formRef.current.querySelectorAll('[required]');
      requiredInputs.forEach(input => {
        input.addEventListener('invalid', invalidHandler);
        input.addEventListener('input', inputHandler);
      });
    }
    return () => {
      if (formRef.current) {
        const requiredInputs = formRef.current.querySelectorAll('[required]');
        requiredInputs.forEach(input => {
          input.removeEventListener('invalid', invalidHandler);
          input.removeEventListener('input', inputHandler);
        });
      }
    };
  }, [formRef, setError, setSubmits, messages]);
  const { minChoices, maxChoices, selectMessage } = getChoiceLimits(question, messages);

  const validate = useCallback(
    (inValue = value) => {
      let newValue = inValue;
      let newError = '';
      if (question.type === selectMany && (minChoices > 0 || maxChoices > 0)) {
        newValue = inValue?.filter(v => v.checked);
        if (!newValue || newValue.length < minChoices) {
          newError = selectMessage;
        }
        if (newValue && maxChoices > 0 && newValue.length > maxChoices) {
          newError = selectMessage;
        }
      }
      if (question.type === selectOne && question.required && !newValue?.filter(v => v.checked)) {
        newError = messages.selectOne;
      }
      if ([selectOne, selectMany].includes(question.type)) {
        const lastInput = [...formRef.current.querySelectorAll('input')].at(-1);
        if (lastInput) {
          if (submits > 0) {
            lastInput.setCustomValidity(newError);
            formRef.current.reportValidity();
          } else {
            lastInput.setCustomValidity('');
          }
        }
      }
      if (
        question.required &&
        (!newValue ||
          ([selectOne, selectMany].includes(question.type) && !newValue.find(v => v.checked)))
      ) {
        newError = messages.requiredAnswer;
      }
      if (question.type === scale) {
        const lastInput = [...formRef.current.querySelectorAll('fieldset > button')].at(-1);
        if (lastInput) {
          if (submits > 0) {
            lastInput.setCustomValidity(newError);
            formRef.current.reportValidity();
          } else {
            lastInput.setCustomValidity('');
          }
        }
      }
      setError(submits === 0 ? null : newError);
      return newError;
    },
    [question, value, minChoices, maxChoices, selectMessage, messages, submits],
  );

  useEffect(() => {
    validate();
  }, [value, validate]);

  const handleChange = useCallback(
    event => {
      setValue(event.target.value);
    },
    [setValue],
  );

  useEffect(() => {
    if (submits && !validate()) {
      onSubmit(question._id, getValue(question.type, value));
      setSubmits(0);
    }
  }, [submits]);

  const handleSubmit = useCallback(
    event => {
      event.preventDefault();
      setSubmits(s => s + 1);
    },
    [setSubmits],
  );

  if (!question) {
    return null;
  }
  const style = question.style || 'numerical';
  const highlightColor = dark ? lightenHex(buttonColor, -0.8) : lightenHex(buttonColor, 0.95);
  const range = getValues(question.range, style);
  const left = range[0];
  const right = range[range.length - 1];
  const replacements = additionalQuestions?.reduce((acc, q) => {
    acc[`answer:${q._id}`] =
      q.optionsAttributes?.find(o => o._id === values[q._id])?.text || values[q._id] || '______';
    return acc;
  }, {});
  const required = useMemo(
    () =>
      question.required ? (
        <small
          className={error === messages.requiredAnswer ? styles.helperTextError : styles.helperText}
        >
          {messages.requiredAnswer}
        </small>
      ) : null,
    [question.required, messages.requiredAnswer, error],
  );

  return (
    <div
      className={styles.root}
      style={{
        '--buttonBackground': dark ? '#333' : '#fff',
        '--textColor': dark ? '#fff' : '#333',
      }}
    >
      {!question.intro || (
        <p className={styles.intro} dir={dir}>
          {replacePlaceholders(question.intro, replacements)}
        </p>
      )}
      <p className={styles.question} dir={dir}>
        {replacePlaceholders(question.text, replacements)}
      </p>
      {!question.description || (
        <p className={styles.description} dir={dir}>
          {replacePlaceholders(question.description, replacements)}
        </p>
      )}
      <form className={styles.form} onSubmit={handleSubmit} ref={formRef}>
        {/* eslint-disable jsx-a11y/no-autofocus */}
        {question.type === freeResponse &&
          ({
            [FreeResponseTypes.date]: (
              <div className={styles.input}>
                {required}
                <input
                  required={!!question.required}
                  className={styles.free}
                  type="date"
                  name="date"
                  id="date"
                  onInput={handleChange}
                  value={value}
                  autoFocus
                />
              </div>
            ),
            [FreeResponseTypes.phone]: (
              <div className={styles.input}>
                {required}
                <input
                  required={!!question.required}
                  className={styles.free}
                  type="tel"
                  pattern="\+?[0-9]+"
                  name="phone"
                  id="phone"
                  onInput={handleChange}
                  value={value}
                  autoFocus
                />
              </div>
            ),
            [FreeResponseTypes.email]: (
              <div className={styles.input}>
                {required}
                <input
                  required={!!question.required}
                  className={styles.free}
                  type="email"
                  name="email"
                  id="email"
                  onInput={handleChange}
                  value={value}
                  autoFocus
                />
              </div>
            ),
            [FreeResponseTypes.number]: (
              <div className={styles.input}>
                {required}
                <input
                  required={!!question.required}
                  className={styles.free}
                  type="number"
                  name="number"
                  id="number"
                  onInput={handleChange}
                  value={value}
                  autoFocus
                />
              </div>
            ),
            [FreeResponseTypes.shortText]: (
              <div className={styles.input}>
                {required}
                <input
                  required={!!question.required}
                  className={styles.free}
                  type="text"
                  name="text"
                  id="text"
                  onInput={handleChange}
                  value={value}
                  autoFocus
                />
              </div>
            ),
          }[question.freeResponseType] || (
            <div className={styles.input}>
              {required}
              <textarea
                required={!!question.required}
                className={styles.free}
                dir={dir}
                name="comment"
                id="comment"
                rows="5"
                onInput={handleChange}
                value={value}
                autoFocus
              />
            </div>
          ))}
        {/* eslint-enable jsx-a11y/no-autofocus */}
        {question.type === scale && (
          <div
            className={`${styles.scaleWrapper} ${style === 'numerical' ? styles.numerical : ''}`}
          >
            <div className={styles.scale}>
              {required}
              <ToggleButtonGroup
                required={!!question.required}
                key={`${question.range}-${style}`}
                value={value}
                onChange={setValue}
                exclusive
                className={`${styles.toggleButtonGroup} ${typeof value === 'number' ? styles.hasValue : ''} ${style === 'numerical' ? '' : styles.iconButtonGroup}`}
                color={buttonColor}
                highlightColor={highlightColor}
              >
                {range.map((buttonValue, index) => (
                  <ToggleButton key={buttonValue} value={buttonValue} style={style} index={index}>
                    {buttonValue === left && !!question.scaleMinLabel && (
                      <span className={styles.scaleLabel}>
                        &nbsp;&mdash;&nbsp;
                        {question.scaleMinLabel}
                      </span>
                    )}
                    {buttonValue === right && !!question.scaleMaxLabel && (
                      <span className={styles.scaleLabel}>
                        &nbsp;&mdash;&nbsp;
                        {question.scaleMaxLabel}
                      </span>
                    )}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </div>
            {!!(question.scaleMinLabel || question.scaleMaxLabel) && (
              <div className={styles.scaleLabelBelow}>
                <span>{question.scaleMinLabel}</span>
                <span>{question.scaleMaxLabel}</span>
              </div>
            )}
          </div>
        )}

        {question.type === selectOne && (
          <>
            <p className={error ? styles.helperTextError : styles.helperText}>
              {messages.selectOne}
            </p>
            <OptionButtonGroup name={question._id} value={value} onChange={setValue}>
              {optionsSorted(question).map(option => (
                <OptionButton
                  key={option._id}
                  value={option._id}
                  label={option.text}
                  color={buttonColor}
                  highlightColor={highlightColor}
                  addComment={option.addComment}
                  messages={messages}
                  requiredComments={question.requiredComments}
                />
              ))}
            </OptionButtonGroup>
          </>
        )}

        {question.type === selectMany && (
          <>
            <p className={error ? styles.helperTextError : styles.helperText}>{selectMessage}</p>
            <OptionButtonGroup multiple name={question._id} value={value} onChange={setValue}>
              {optionsSorted(question).map(option => (
                <OptionButton
                  key={option._id}
                  value={option._id}
                  label={option.text}
                  color={buttonColor}
                  highlightColor={highlightColor}
                  addComment={option.addComment}
                  messages={messages}
                  requiredComments={question.requiredComments}
                />
              ))}
            </OptionButtonGroup>
          </>
        )}
        {disabled ? (
          <button
            title={stopEditingMessage}
            disabled
            type="submit"
            className={styles.outlinedButton}
          >
            {question.buttonLabel || (hasNext ? messages.nextQuestion : messages.done)}
          </button>
        ) : (
          <button type="submit" className={styles.outlinedButton} disabled={!!error}>
            {question.buttonLabel || (hasNext ? messages.nextQuestion : messages.done)}
          </button>
        )}
      </form>
      {hasPrevious && (
        <button
          disabled={disabled}
          type="button"
          onClick={onPrevious}
          color="inherit"
          className={styles.textButton}
        >
          {messages.previousQuestion}
        </button>
      )}
    </div>
  );
};

export default AdditionalQuestionForm;

export const LogLevel = {
  None: 0, Error: 1, Warning: 2, Info: 3, Log: 4, Debug: 5,
};

const loggerSettings = {
  output: console,
  logLevel: LogLevel.Log,
};

export class Logger {
  constructor(name) {
    const t = this;
    t.name = `[${name}]`;
    Object.entries(LogLevel).forEach(([k, v]) => {
      if (v > LogLevel.None) {
        t[k.toLowerCase()] = args => this.emit(v, args);
      }
    });
  }

  static setOutput(output) {
    loggerSettings.output = output;
  }

  static setLevel(level) {
    loggerSettings.logLevel = level;
  }

  emit(logLevel, args) {
    return loggerSettings.logLevel >= logLevel
      ? loggerSettings.output.log(...[this.name].concat(args))
      : null;
  }
}

/* eslint-disable react/prop-types */
import isValueSelected from 'client/modules/Response/components/AdditionalQuestionForm/isValueSelected';
import lightenHex from 'client/util/lightenHex';
import * as React from 'preact';

// eslint-disable-next-line no-unused-vars
const { h } = React;

const isFragment = obj => {
  if (obj.type) {
    return obj.type === React.Fragment;
  }
  return obj === React.Fragment;
};

const ToggleButtonGroup = props => {
  const {
    children,
    className,
    color = '#333',
    disabled = false,
    exclusive = false,
    fullWidth = false,
    onChange,
    size = 'medium',
    value,
    highlightColor = lightenHex(color, 0.95),
    required = false,
  } = props;

  const handleChange = (event, buttonValue) => {
    if (!onChange) {
      return;
    }

    const index = value && value.indexOf(buttonValue);
    let newValue;

    if (value && index >= 0) {
      newValue = value.slice();
      newValue.splice(index, 1);
    } else {
      newValue = value ? value.concat(buttonValue) : [buttonValue];
    }

    onChange(newValue);
  };

  const handleExclusiveChange = (event, buttonValue) => {
    if (!onChange) {
      return;
    }

    onChange(value === buttonValue ? null : buttonValue);
  };

  return (
    <fieldset
      className={className}
      style={{
        '--borderColor': color,
        '--backgroundColor': highlightColor,
      }}
    >
      {children.map(child => {
        if (!React.isValidElement(child)) {
          return null;
        }

        if (process.env.NODE_ENV !== 'production') {
          if (isFragment(child)) {
            console.error(
              [
                "The ToggleButtonGroup component doesn't accept a Fragment as a child.",
                'Consider providing an array instead.',
              ].join('\n'),
            );
          }
        }

        return React.cloneElement(child, {
          className: child.props.className,
          onChange: exclusive ? handleExclusiveChange : handleChange,
          selected:
            child.props.selected === undefined
              ? isValueSelected(child.props.value, value)
              : child.props.selected,
          size: child.props.size || size,
          fullWidth,
          color: child.props.color || color,
          disabled: child.props.disabled || disabled,
        });
      })}
    </fieldset>
  );
};

export default ToggleButtonGroup;

import matchesRule from './matchesRule';

const DEBUG = process.env.NODE_ENV !== 'production';

function debug(...args) {
  if (DEBUG) {
    console.log('[SurveyVisibilityManager]', ...args);
  }
}

class SurveyVisibilityManager {
  constructor(config) {
    this.config = config;
    this.currentUrl = window.location.href;
    this.isVisible = false;
    this.timeoutId = null;
    this.setupListeners();
    debug('Initialized', { config: this.config });
  }

  setupListeners() {
    window.addEventListener('popstate', this.handleUrlChange.bind(this));
    this.setupSpaUrlChangeDetection();
    this.intervalId = setInterval(this.checkUrlChange.bind(this), 1000);

    if (this.getTriggerType() === 'scroll') {
      window.addEventListener('scroll', this.handleScroll.bind(this));
    }

    if (this.getTriggerType() === 'exit') {
      this.setupExitIntentDetection();
    }
  }

  setupSpaUrlChangeDetection() {
    let lastUrl = window.location.href;
    this.observer = new MutationObserver(() => {
      const url = window.location.href;
      if (url !== lastUrl) {
        lastUrl = url;
        this.handleUrlChange();
      }
    });
    this.observer.observe(document.body, { childList: true, subtree: true });
  }

  setupExitIntentDetection() {
    this.handleExitIntent = this.handleExitIntent.bind(this);
    document.addEventListener('mousemove', this.handleExitIntent);
  }

  handleExitIntent(e) {
    if (e.clientY <= 0) {
      this.evaluateSurveyVisibility();
    }
  }

  checkUrlChange() {
    if (this.currentUrl !== window.location.href) {
      this.handleUrlChange();
    }
  }

  handleUrlChange() {
    const newUrl = window.location.href;
    debug('Handling URL change', {
      oldUrl: this.currentUrl,
      newUrl,
      isVisible: this.isVisible,
      closeOnUrlChange: this.config.triggers?.closeOnUrlChange,
    });

    if (this.currentUrl !== newUrl) {
      this.currentUrl = newUrl;

      if (this.isVisible) {
        if (this.config.triggers?.closeOnUrlChange) {
          debug('Hiding survey due to URL change');
          this.hideSurvey();
        } else {
          debug('Survey remains visible after URL change');
        }
      } else {
        debug('Survey not visible, evaluating visibility after URL change');
        this.evaluateSurveyVisibility();
      }
    }
  }

  getTriggerType() {
    if (this.config.triggers?.triggerOn) {
      return this.config.triggers.triggerOn;
    }
    if (this.config.timeDelayOnPage > 0) {
      return 'delay';
    }
    return 'instantly';
  }

  evaluateSurveyVisibility() {
    debug('Evaluating survey visibility');
    if (this.isVisible && (this.config.triggers?.closeOnUrlChange ?? false)) {
      debug('Hiding survey due to URL change');
      this.hideSurvey();
    } else if (this.shouldShowSurvey()) {
      const triggerType = this.getTriggerType();
      debug('Survey should be shown', { triggerType });
      if (triggerType === 'delay') {
        this.setDelayedShow();
      } else if (triggerType === 'instantly') {
        this.showSurvey();
      } else if (triggerType === 'scroll') {
        // For scroll trigger, we don't show the survey immediately
        // Instead, we wait for the scroll event to trigger it
        debug('Waiting for scroll threshold to be reached');
      }
    }
  }

  shouldShowSurvey() {
    debug('Checking if survey should be shown');
    // If triggers don't exist, show on all pages (backwards compatibility)
    if (!this.config.triggers || this.config.triggers.showOn === 'all') {
      debug('No triggers or showOn is "all", showing survey');
      return true;
    }

    const passesInclusion = (this.config.triggers.inclusionRules || []).some(rule =>
      matchesRule(rule, this.currentUrl),
    );
    const passesExclusion = !(this.config.triggers.exclusionRules || []).some(rule =>
      matchesRule(rule, this.currentUrl),
    );

    debug('Rule evaluation results', { passesInclusion, passesExclusion });
    return passesInclusion && passesExclusion;
  }

  setDelayedShow() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    debug(`Setting timeout for ${this.config.timeDelayOnPage} seconds`);
    this.timeoutId = setTimeout(() => this.showSurvey(), this.config.timeDelayOnPage * 1000);
  }

  showSurvey() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
    if (!this.isVisible) {
      this.isVisible = true;
      debug('Showing survey');
      this.config.onShow();
    } else {
      debug('Survey not shown', { isAlreadyVisible: this.isVisible });
    }
  }

  hideSurvey() {
    if (this.isVisible) {
      this.isVisible = false;
      debug('Hiding survey');
      this.config.onHide();
    }
  }

  handleScroll() {
    const scrollPercentage =
      (window.scrollY / (document.documentElement.scrollHeight - window.innerHeight)) * 100;
    debug('Scroll percentage', { scrollPercentage });
    if (
      this.getTriggerType() === 'scroll' &&
      scrollPercentage >= (this.config.triggers?.scrollPercentage ?? 0) &&
      this.shouldShowSurvey()
    ) {
      debug('Scroll threshold reached and inclusion rules passed, showing survey');
      this.showSurvey();
    }
  }

  triggerEvent() {
    if (this.getTriggerType() === 'event') {
      debug('Event triggered, evaluating survey visibility');
      this.evaluateSurveyVisibility();
    }
  }

  destroy() {
    window.removeEventListener('popstate', this.handleUrlChange);
    this.observer.disconnect();
    clearInterval(this.intervalId);

    if (this.getTriggerType() === 'scroll') {
      window.removeEventListener('scroll', this.handleScroll);
    }

    if (this.getTriggerType() === 'exit') {
      document.removeEventListener('mousemove', this.handleExitIntent);
    }
  }
}

export default SurveyVisibilityManager;

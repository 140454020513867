const componentToHex = (c) => {
  const hex = Math.round(c).toString(16);
  return hex.length === 1 ? `0${ hex}` : hex;
};

const lightenHex = (color, coefficient) => {
  const c = color.slice(1);
  const re = new RegExp(`.{1,${c.length >= 6 ? 2 : 1}}`, 'g');
  let colors = c.match(re);

  if (colors && colors[0].length === 1) {
    colors = colors.map(n => n + n);
  }

  colors = colors.map((n, index) => {
    const val = parseInt(n, 16);
    if (index < 3) {
      if (coefficient > 0) {
        return val + (255 - val) * coefficient;
      }
      return val + val * coefficient;
    }
    return Math.round(val / 255 * 1000) / 1000;
  });

  return colors ? `#${colors.map(n => componentToHex(n)).join('')}` : null;
};

export default lightenHex;

const matchesRule = (rule, url) => {
  switch (rule.type) {
    case 'exact':
      return url === rule.value;
    case 'contains':
      return url.includes(rule.value);
    case 'regex':
      return new RegExp(rule.value).test(url);
    default:
      return false;
  }
};

export default matchesRule;
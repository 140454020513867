/* eslint-disable react/prop-types */
import stars from 'client/modules/Surveys/pages/WebSurveyWidget/images/star.svg';
import thumbs from 'client/modules/Surveys/pages/WebSurveyWidget/images/thumb.svg';
import hearts from 'client/modules/Surveys/pages/WebSurveyWidget/images/heart.svg';
import csat1 from 'client/modules/Surveys/pages/WebSurveyWidget/images/csat-1.svg';
import csat2 from 'client/modules/Surveys/pages/WebSurveyWidget/images/csat-2.svg';
import csat3 from 'client/modules/Surveys/pages/WebSurveyWidget/images/csat-3.svg';
import csat4 from 'client/modules/Surveys/pages/WebSurveyWidget/images/csat-4.svg';
import csat5 from 'client/modules/Surveys/pages/WebSurveyWidget/images/csat-5.svg';
import * as React from 'preact';
import * as PropTypes from 'prop-types';

import styles from './AdditionalQuestionForm.css';

const iconMap = {
  hearts,
  thumbs,
  stars,
};

const csatMap = {
  1: csat1,
  2: csat2,
  3: csat3,
  4: csat4,
  5: csat5,
};

const ToggleButtonValue = (props) => {
  const animStart = (props.index + 1) * 0.032;
  if (props.style === 'numerical') {
    return <>{props.buttonValue}</>;
  }
  if (props.style === 'faces') {
    return (
      <div
        style={`animation: ${styles.pnWebBtnAnim} 0.325s linear ${animStart}s 1 normal both !important;`}
        dangerouslySetInnerHTML={{ __html: csatMap[`${props.buttonValue}`] }}
      />
    );
  }
  return (
    <div
      style={`animation: ${styles.pnWebBtnAnim} 0.325s linear ${animStart}s 1 normal both !important;`}
      dangerouslySetInnerHTML={{ __html: iconMap[props.style] }}
    />
  );
};

ToggleButtonValue.propTypes = {
  buttonValue: PropTypes.number.isRequired,
  style: PropTypes.string.isRequired,
};
const ToggleButton = (props) => {
  const handleChange = (event) => {
    event.preventDefault();
    props.onChange(event, props.value);
  };
  return (
    <button
      onClick={handleChange}
      type="button"
      value={props.value}
      className={`${styles[props.style]} ${props.style === 'numerical' ? styles.toggleButton : styles.iconButton} ${props.selected ? styles.selected : ''}`}
    >
      <ToggleButtonValue buttonValue={props.value} style={props.style} index={props.index} />
      {props.children}
    </button>
  );
};

export default ToggleButton;

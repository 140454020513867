/* eslint-disable react/prop-types */
import cx from 'classnames';
import useOptionButtonGroup from 'client/modules/Response/components/AdditionalQuestionForm/OptionButtons/useOptionButtonGroup';
import lightenHex from 'client/util/lightenHex';
import * as React from 'preact';
import { useCallback, useEffect, useRef, useState } from 'preact/hooks';
import styles from './OptionButton.css';

// eslint-disable-next-line no-unused-vars
const { h } = React;

const Comment = ({ messages, value, onChange, required = false }) => {
  const commentRef = useRef();
  const [error, setError] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  useEffect(() => {
    commentRef.current.focus();
    const invalidHandler = () => {
      setSubmitted(true);
      setError(messages.requiredAnswer);
    };
    if (commentRef.current) {
      commentRef.current.addEventListener('invalid', invalidHandler);
    }
    return () => {
      if (commentRef.current) {
        commentRef.current.removeEventListener('invalid', invalidHandler);
      }
    };
  }, []);
  const handleInput = useCallback(
    e => {
      if (error && commentRef.current.value) {
        setError(null);
      } else if (submitted && !error && !commentRef.current.value) {
        setError(messages.requiredAnswer);
      }
      onChange(e);
    },
    [error],
  );
  const handleChange = useCallback(() => {
    if (submitted) {
      commentRef.current?.closest('form').reportValidity();
    }
  }, [onChange, submitted]);
  return (
    <>
      {!required || (
        <small className={error ? styles.helperTextError : styles.helperText}>
          {messages.requiredAnswer}
        </small>
      )}
      <textarea
        required={required}
        value={value}
        ref={commentRef}
        className={styles.comment}
        placeholder={messages.commentPlaceholder}
        onChange={handleChange}
        onInput={handleInput}
      />
    </>
  );
};

const getValue = (option, value) => {
  if (!option?.value) {
    return false;
  }
  return option.value.find(v => v.id === value) || {};
};

const OptionButton = ({
  color = '#333',
  value,
  label = false,
  highlightColor = lightenHex(color, 0.95),
  addComment = false,
  messages,
  requiredComments = false,
}) => {
  const option = useOptionButtonGroup();
  const { checked, comment } = getValue(option, value);
  const handleCommentChange = e => {
    option.onCommentChange(e, value);
  };

  return (
    <label
      className={cx({
        [styles.root]: true,
        [styles.checked]: checked,
      })}
      htmlFor={`o-${option.name}-${value}`}
      style={{
        '--borderColor': checked ? color : '#ccc',
        ...(checked ? { '--backgroundColor': highlightColor } : {}),
      }}
    >
      <div className={styles.wrapper}>
        <span className={styles.inputWrapper}>
          <input
            id={`o-${option.name}-${value}`}
            className={styles.input}
            type={option.multiple ? 'checkbox' : 'radio'}
            checked={checked}
            value={value}
            onChange={option?.onChange}
          />
          {!checked || (
            <svg
              style={{ color }}
              className={styles.icon}
              focusable="false"
              aria-hidden
              viewBox="0 0 24 24"
            >
              <path color={color} d="M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
            </svg>
          )}
        </span>
        <span className={styles.label}>{label}</span>
      </div>
      {!checked || !addComment || (
        <Comment
          value={comment}
          messages={messages}
          onChange={handleCommentChange}
          required={requiredComments}
        />
      )}
    </label>
  );
};

export default OptionButton;

const e = encodeURIComponent;
const toISO = Date.prototype.toISOString;

export default (object) => {
  const encodeObj = (obj, prefix) => {
    const ret = Object.entries(obj).map(([k, value]) => {
      let v = value;
      const key = prefix ? `${prefix}[${e(k)}]` : e(k);
      if (v && typeof v === 'object') {
        return encodeObj(v, key);
      }
      if (v && v instanceof Date) {
        v = toISO().call(v);
      } else if (v === null) {
        v = '';
      } else if (typeof v === 'undefined') {
        return null;
      } else {
        v = e(v.toString());
      }
      return `${key}=${v}`;
    });
    return ret.filter(x => x !== null).join('&');
  };
  return encodeObj(object);
};

/** @jsx h */
import * as React from 'preact';
import WebSurveyWidget from './modules/Surveys/pages/WebSurveyWidget/WebSurveyWidgetContainer';

const { h, render } = React;

const script = document.currentScript
/* Polyfill */ || Array.prototype.slice.call(document.getElementsByTagName('script')).pop();
const varName = script.getAttribute('data-v') || 'promoterNinja';
const pn = window[varName];
const defaultId = 'pn-survey';

let mountApp = pn?.qs ? document.querySelector(pn?.qs) : document.getElementById(defaultId);
if (!mountApp) {
  mountApp = document.createElement('div');
  mountApp.setAttribute('id', defaultId);
  document.body.appendChild(mountApp);
} else if (pn?.cs === 'replace') {
  mountApp.innerHTML = '';
}

render(
  <WebSurveyWidget />,
  mountApp,
);

const FreeResponseTypes = {
  shortText: 'short_text',
  longText: 'long_text',
  number: 'number',
  email: 'email',
  phone: 'phone',
  date: 'date',
};

export default FreeResponseTypes;
